import TVKEYS from "../../tv/tvkeys";
import AbstractView from "../AbstractView";
import { deactivateApp } from "../../components/broadcast-object";
import damaLogo from '../../../images/games/dama/dama.png';
import rulesButton from '../../../images/games/dama/damakuralları.png';
import backButton from '../../../images/games/dama/gerituşu.png';
import newGameButton from '../../../images/games/dama/yenioyun.png';
import rulesTable from '../../../images/games/dama/kuraltablosuarkaplansiz.png';
import blackPiece from '../../../images/games/dama/kahverengitaş.png';
import whitePiece from '../../../images/games/dama/kremtaş.png';
import whiteKing from '../../../images/games/dama/kremkraltaş.png';
import blackKing from '../../../images/games/dama/kahverengikraltaş.png';

class Dama extends AbstractView {
    constructor(params) {
        super(params);
        this.setTitle('Dama');
        this.setActiveHeader('header-games');
        this.playing = true;
        this.handled = false;
    }

    async getHtml() {
        return ` 
        <div class="dama-wrapper">
            <div class="left-column">
                <div id="title-text">
                    <img src="${damaLogo}" alt="Dama Logo" />
                </div>
                <div class="dama-container">
                    ${Array.from({ length: 64 }, (_, i) => `<div class="dama-cell" id="cell${i}"></div>`).join('')}
                </div>
                <div class="notification">
                    <div class="notification-text" id="notification">
                        Beyaz taşları oynatınız.
                    </div>
                </div>
            </div>
            <div class="right-column">
                <div class="button-container">
                    <div class="button" id="rulesButton">
                        <img src="${rulesButton}" alt="Rules Button" />
                    </div>
                    <div class="button" id="startNewButton">
                        <img src="${newGameButton}" alt="NewGame Button" />
                    </div>
                    <div class="button" id="backButton" href="/games" data-link="" tabindex="-1">
                        <img src="${backButton}" alt="Back Button" />
                    </div>
                </div>
                <div id="scoreboard" class="scoreboard">
                    <div>Oyuncu yenen taşlar: <span id="playerXWins">0</span></div>
                    <div>Bilgisayar yenen taşlar: <span id="playerOWins">0</span></div>
                </div>
            </div>
            <div id="modal-container" class="modal-container">
                <div id="overlay" class="overlay"></div>
                <div id="rulesTable" class="rules">
                    <img src="${rulesTable}" alt="Rules Table" />
                </div>
            </div>

        </div>
        `;
    }

    async navigate() {
    }

    async afterRender() {
        var currentPlayer = 'W';
        var startingBoard = ['', '', '', '', '', '', '', '',
            'B', 'B', 'B', 'B', 'B', 'B', 'B', 'B',
            'B', 'B', 'B', 'B', 'B', 'B', 'B', 'B',
            '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '',
            'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W',
            'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W',
            '', '', '', '', '', '', '', '',
        ];
        var currentSelection = 56;
        var selectedPiece = null;
        var blackScore = 0;
        var whiteScore = 0;
        var captureIndex = null;

        const game = () => {
            function handleEnterKey() {
                var capturingMovesAvailable = hasCapturingMoves(currentPlayer);
                showNotification(capturingMovesAvailable ? 'Taşı almak zorunlu' : 'Beyaz taşları oynatınız.');

                if (selectedPiece === null && getPossibleMoves(currentSelection).length > 0) {
                    selectPiece(capturingMovesAvailable);
                } else if (selectedPiece === currentSelection) {
                    clearHighlights();
                    selectedPiece = null;
                } else {
                    moveSelectedPiece();
                }
            }

            function selectPiece(capturingMovesAvailable) {
                if (startingBoard[currentSelection] === currentPlayer || startingBoard[currentSelection] === currentPlayer + 'K') {
                    var possibleMoves = getPossibleMoves(currentSelection);
                    var hasCapture = false;
                    for (var i = 0; i < possibleMoves.length; i++) {
                        if (possibleMoves[i].capture !== null) {
                            hasCapture = true;
                            break;
                        }
                    }

                    if (!capturingMovesAvailable || hasCapture) {
                        selectedPiece = currentSelection;
                        highlightPossibleMoves(selectedPiece);
                    }
                }
            }

            function moveSelectedPiece() {
                if (document.getElementById('cell' + currentSelection).classList.contains('highlight')) {
                    movePiece(selectedPiece, currentSelection);
                    clearHighlights();
                    if (captureIndex !== null && hasCapturingMovesAt(currentSelection)) {
                        selectedPiece = currentSelection;
                        highlightPossibleMoves(selectedPiece);
                    } else {
                        selectedPiece = null;
                        if (!checkEndGame()) {
                            switchTurn();
                        }
                    }
                }
            }

            function showNotification(message) {
                document.getElementById('notification').innerText = message;
            }

            function showRules() {
                var modal = document.getElementById('modal-container');
                if (modal.style.display === 'block') {
                    modal.style.display = 'none';
                } else {
                    modal.style.display = 'block';
                }
            }

            function hasCapturingMovesAt(index) {
                var moves = getPossibleMoves(index);
                for (var i = 0; i < moves.length; i++) {
                    if (moves[i].capture !== null) {
                        return true;
                    }
                }
                return false;
            }

            function moveSelection(change) {
                unhighlightSquare(currentSelection);
                currentSelection = (currentSelection + change + 64) % 64;
                highlightSquare(currentSelection);
            }


            function highlightSquare(index) {
                var cell = document.getElementById('cell' + index);
                if (cell) {
                    cell.classList.add('highlight-square');
                }
            }

            function unhighlightSquare(index) {
                var cell = document.getElementById('cell' + index);
                if (cell) {
                    cell.classList.remove('highlight-square');
                }
            }

            function highlightPossibleMoves(index) {
                var possibleMoves = getPossibleMoves(index);
                for (var i = 0; i < possibleMoves.length; i++) {
                    var move = possibleMoves[i];
                    var cell = document.getElementById('cell' + move.to);
                    if (cell) {
                        cell.classList.add('highlight');
                    }
                }
            }
            function getAllMoves(board, player) {
                var moves = [];
                for (var i = 0; i < board.length; i++) {
                    if (board[i] === player || board[i] === player + 'K') {
                        var possibleMoves = getPossibleMovesForBoard(i, board);
                        for (var move of possibleMoves) {
                            moves.push({
                                from: i,
                                to: move.to,
                                capture: move.capture
                            });
                        }
                    }
                }
                var capturingMoves = moves.filter(move => move.capture !== null);
                return capturingMoves.length > 0 ? capturingMoves : moves;
            }

            function getPossibleMovesForBoard(index, board) {
                var moves = [];
                var piece = board[index];
                var directions = [];

                if (piece === 'W') {
                    directions = [-8, -1, 1];
                } else if (piece === 'B') {
                    directions = [8, -1, 1];
                } else if (piece === 'WK' || piece === 'BK') {
                    directions = [-8, 8, -1, 1];
                }

                for (var i = 0; i < directions.length; i++) {
                    var direction = directions[i];
                    var newIndex = index + direction;

                    while (isValidMoveForBoard(board, index, newIndex, piece)) {
                        moves.push({ to: newIndex, capture: null });
                        newIndex += direction;
                        if (newIndex < 0 || newIndex >= 64) break;
                    }
                    var captureIndex = newIndex + direction;

                    while (isValidCaptureForBoard(board, index, newIndex, captureIndex, piece)) {
                        moves.push({ to: captureIndex, capture: newIndex });
                        captureIndex += direction;
                        if (captureIndex < 0 || captureIndex >= 64) break;
                    }
                }

                var capturingMoves = [];
                for (var j = 0; j < moves.length; j++) {
                    if (moves[j].capture !== null) {
                        capturingMoves.push(moves[j]);
                    }
                }

                return capturingMoves.length > 0 ? capturingMoves : moves;
            }

            function isValidMoveForBoard(board, fromIndex, toIndex, piece) {
                if (toIndex < 0 || toIndex >= 64) return false;
                if (board[toIndex] !== '') return false;

                var fromRow = Math.floor(fromIndex / 8);
                var toRow = Math.floor(toIndex / 8);
                var fromCol = fromIndex % 8;
                var toCol = toIndex % 8;

                if (piece === 'W' || piece === 'B') {
                    return Math.abs(fromRow - toRow) + Math.abs(fromCol - toCol) === 1;
                } else if (piece === 'WK' || piece === 'BK') {
                    var step;
                    if (fromRow === toRow) {
                        // Horizontal move
                        step = (toCol > fromCol) ? 1 : -1;
                        for (var col = fromCol + step; col !== toCol; col += step) {
                            if (board[fromRow * 8 + col] !== '') return false;
                        }
                    } else if (fromCol === toCol) {
                        // Vertical move
                        step = (toRow > fromRow) ? 8 : -8;
                        for (var row = fromRow + step / 8; row !== toRow; row += step / 8) {
                            if (board[row * 8 + fromCol] !== '') return false;
                        }
                    } else {
                        return false;
                    }
                }

                return true;
            }


            function isValidCaptureForBoard(board, fromIndex, overIndex, toIndex, piece) {
                if (toIndex < 0 || toIndex >= 64) return false;
                if (board[toIndex] !== '') return false;
                if (overIndex < 0 || overIndex >= 64) return false;
                if (board[overIndex] === '' || board[overIndex][0] === piece[0]) return false;

                var fromRow = Math.floor(fromIndex / 8);
                var toRow = Math.floor(toIndex / 8);
                var fromCol = fromIndex % 8;
                var toCol = toIndex % 8;

                if (piece === 'W' || piece === 'B') {
                    return Math.abs(fromRow - toRow) + Math.abs(fromCol - toCol) === 2;
                } else if (piece === 'WK' || piece === 'BK') {
                    var step;
                    var opponentFound;
                    if (fromRow === toRow) {
                        step = (toCol > fromCol) ? 1 : -1;
                        opponentFound = false;
                        for (var col = fromCol + step; col !== toCol; col += step) {
                            if (board[fromRow * 8 + col] !== '') {
                                if (col === overIndex % 8 && !opponentFound) {
                                    opponentFound = true;
                                } else {
                                    return false;
                                }
                            }
                        }
                        return opponentFound;
                    } else if (fromCol === toCol) {
                        step = (toRow > fromRow) ? 8 : -8;
                        opponentFound = false;
                        for (var row = fromRow + step / 8; row !== toRow; row += step / 8) {
                            if (board[row * 8 + fromCol] !== '') {
                                if (row === Math.floor(overIndex / 8) && !opponentFound) {
                                    opponentFound = true;
                                } else {
                                    return false;
                                }
                            }
                        }
                        return opponentFound;
                    } else {
                        return false;
                    }
                }

                return true;
            }
            function isGameOver(board) {
                var whitePieces = board.filter(piece => piece === 'W' || piece === 'WK').length;
                var blackPieces = board.filter(piece => piece === 'B' || piece === 'BK').length;

                if (whitePieces === 0 || blackPieces === 0) {
                    return true;
                }

                var whiteMoves = getAllMoves(board, 'W');
                var blackMoves = getAllMoves(board, 'B');

                return whiteMoves.length === 0 || blackMoves.length === 0;
            }


            function minimax(board, depth, isMaximizing, alpha, beta) {
                if (depth === 0 || isGameOver(board)) {
                    return { score: evaluateBoard(board) };
                }

                var currentPlayer = isMaximizing ? 'B' : 'W';
                var moves = getAllMoves(board, currentPlayer);

                if (moves.length === 0) {
                    return { score: isMaximizing ? -Infinity : Infinity };
                }

                var bestMove = null;

                if (isMaximizing) {
                    var maxEval = -Infinity;
                    var evaluation = null;
                    var newBoard = null;
                    var move = null;
                    for (move of moves) {
                        newBoard = board.slice();
                        newBoard[move.to] = newBoard[move.from];
                        newBoard[move.from] = '';
                        if (move.capture !== null) {
                            newBoard[move.capture] = '';
                        }
                        if (move.to < 8 && newBoard[move.to] === 'W') newBoard[move.to] = 'WK';
                        if (move.to >= 56 && newBoard[move.to] === 'B') newBoard[move.to] = 'BK';

                        evaluation = minimax(newBoard, depth - 1, false, alpha, beta).score;
                        if (evaluation > maxEval) {
                            maxEval = evaluation;
                            bestMove = move;
                        }
                        alpha = Math.max(alpha, evaluation);
                        if (beta <= alpha) {
                            break;
                        }
                    }
                    return { score: maxEval, move: bestMove };
                } else {
                    var minEval = Infinity;
                    for (move of moves) {
                        newBoard = board.slice();
                        newBoard[move.to] = newBoard[move.from];
                        newBoard[move.from] = '';
                        if (move.capture !== null) {
                            newBoard[move.capture] = '';
                        }
                        if (move.to < 8 && newBoard[move.to] === 'W') newBoard[move.to] = 'WK';
                        if (move.to >= 56 && newBoard[move.to] === 'B') newBoard[move.to] = 'BK';

                        evaluation = minimax(newBoard, depth - 1, true, alpha, beta).score;
                        if (evaluation < minEval) {
                            minEval = evaluation;
                            bestMove = move;
                        }
                        beta = Math.min(beta, evaluation);
                        if (beta <= alpha) {
                            break;
                        }
                    }
                    return { score: minEval, move: bestMove };
                }
            }

            function getPossibleMoves(index) {
                var moves = [];
                var piece = startingBoard[index];
                var directions = [];

                if (piece === 'W') {
                    directions = [-8, -1, 1];
                } else if (piece === 'B') {
                    directions = [8, -1, 1];
                } else if (piece === 'WK' || piece === 'BK') {
                    directions = [-8, 8, -1, 1];
                }

                for (var i = 0; i < directions.length; i++) {
                    var direction = directions[i];
                    var newIndex = index + direction;

                    while (isValidMove(index, newIndex, piece)) {
                        moves.push({ to: newIndex, capture: null });
                        newIndex += direction;
                        if (newIndex < 0 || newIndex >= 64) break;
                    }
                    var captureIndex = newIndex + direction;

                    while (isValidCapture(index, newIndex, captureIndex, piece)) {
                        moves.push({ to: captureIndex, capture: newIndex });
                        captureIndex += direction;
                        if (captureIndex < 0 || captureIndex >= 64) break;
                    }
                }

                var capturingMoves = [];
                for (var j = 0; j < moves.length; j++) {
                    if (moves[j].capture !== null) {
                        capturingMoves.push(moves[j]);
                    }
                }

                return capturingMoves.length > 0 ? capturingMoves : moves;
            }

            function hasCapturingMoves(player) {
                for (var i = 0; i < 64; i++) {
                    if (startingBoard[i] === player || startingBoard[i] === player + 'K') {
                        var moves = getPossibleMoves(i);
                        for (var j = 0; j < moves.length; j++) {
                            if (moves[j].capture !== null) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            }

            function isValidMove(fromIndex, toIndex, piece) {
                if (toIndex < 0 || toIndex >= 64) return false;
                if (startingBoard[toIndex] !== '') return false;

                var fromRow = Math.floor(fromIndex / 8);
                var toRow = Math.floor(toIndex / 8);
                var fromCol = fromIndex % 8;
                var toCol = toIndex % 8;

                if (piece === 'W' || piece === 'B') {
                    return Math.abs(fromRow - toRow) + Math.abs(fromCol - toCol) === 1;
                } else if (piece === 'WK' || piece === 'BK') {
                    var step;
                    if (fromRow === toRow) {
                        // Horizontal move
                        step = (toCol > fromCol) ? 1 : -1;
                        for (var col = fromCol + step; col !== toCol; col += step) {
                            if (startingBoard[fromRow * 8 + col] !== '') return false;
                        }
                    } else if (fromCol === toCol) {
                        // Vertical move
                        step = (toRow > fromRow) ? 8 : -8;
                        for (var row = fromRow + step / 8; row !== toRow; row += step / 8) {
                            if (startingBoard[row * 8 + fromCol] !== '') return false;
                        }
                    } else {
                        return false;
                    }
                }

                return true;
            }


            function isValidCapture(fromIndex, overIndex, toIndex, piece) {
                if (toIndex < 0 || toIndex >= 64) return false;
                if (startingBoard[toIndex] !== '') return false;
                if (overIndex < 0 || overIndex >= 64) return false;
                if (startingBoard[overIndex] === '' || startingBoard[overIndex][0] === piece[0]) return false;

                var fromRow = Math.floor(fromIndex / 8);
                var toRow = Math.floor(toIndex / 8);
                var fromCol = fromIndex % 8;
                var toCol = toIndex % 8;

                if (piece === 'W' || piece === 'B') {
                    return Math.abs(fromRow - toRow) + Math.abs(fromCol - toCol) === 2;
                } else if (piece === 'WK' || piece === 'BK') {
                    var step;
                    var opponentFound;
                    if (fromRow === toRow) {
                        step = (toCol > fromCol) ? 1 : -1;
                        opponentFound = false;
                        for (var col = fromCol + step; col !== toCol; col += step) {
                            if (startingBoard[fromRow * 8 + col] !== '') {
                                if (col === overIndex % 8 && !opponentFound) {
                                    opponentFound = true;
                                } else {
                                    return false;
                                }
                            }
                        }
                        return opponentFound;
                    } else if (fromCol === toCol) {
                        step = (toRow > fromRow) ? 8 : -8;
                        opponentFound = false;
                        for (var row = fromRow + step / 8; row !== toRow; row += step / 8) {
                            if (startingBoard[row * 8 + fromCol] !== '') {
                                if (row === Math.floor(overIndex / 8) && !opponentFound) {
                                    opponentFound = true;
                                } else {
                                    return false;
                                }
                            }
                        }
                        return opponentFound;
                    } else {
                        return false;
                    }
                }

                return true;
            }

            function clearHighlights() {
                var highlightedCells = document.querySelectorAll('.highlight');
                for (var i = 0; i < highlightedCells.length; i++) {
                    highlightedCells[i].classList.remove('highlight');
                }
            }

            function checkEndGame() {
                if (whiteScore === 16) {
                    document.getElementById('notification').innerText = 'Beyaz Kazandı';
                    return true;
                } else if (blackScore === 16) {
                    document.getElementById('notification').innerText = 'Siyah Kazandı';
                    return true;
                } else if (whiteScore === 15 && blackScore === 15) {
                    document.getElementById('notification').innerText = 'Beyaz taşları oynatınız.';
                    return true;
                }
                return false;
            }

            function movePiece(fromIndex, toIndex) {
                captureIndex = null;
                var possibleMoves = getPossibleMoves(fromIndex);
                for (var i = 0; i < possibleMoves.length; i++) {
                    if (possibleMoves[i].to === toIndex) {
                        captureIndex = possibleMoves[i].capture;
                    }
                }

                startingBoard[toIndex] = startingBoard[fromIndex];
                startingBoard[fromIndex] = '';
                if (captureIndex !== null) {
                    if (startingBoard[captureIndex] === 'B' || startingBoard[captureIndex] === 'BK') {
                        whiteScore++;
                        document.getElementById('playerXWins').innerText = whiteScore;
                    } else if (startingBoard[captureIndex] === 'W' || startingBoard[captureIndex] === 'WK') {
                        blackScore++;
                        document.getElementById('playerOWins').innerText = blackScore;
                    }
                    startingBoard[captureIndex] = '';
                }
                renderBoard();
                if (toIndex < 8 && startingBoard[toIndex] === 'W') {
                    startingBoard[toIndex] = 'WK';
                }
                if (toIndex >= 56 && startingBoard[toIndex] === 'B') {
                    startingBoard[toIndex] = 'BK';
                }
                document.getElementById('notification').innerText = 'Beyaz taşları oynatınız.';
            }

            function switchTurn() {
                currentPlayer = (currentPlayer === 'W') ? 'B' : 'W';
                selectedPiece = null;
                if (currentPlayer === 'B') {
                    setTimeout(computerMove, 500);
                }
            }

            function evaluateBoard(board) {
                var score = 0;
                for (var i = 0; i < board.length; i++) {
                    var piece = board[i];
                    if (piece === 'B') {
                        score += 3;
                    } else if (piece === 'BK') {
                        score += 5;
                    } else if (piece === 'W') {
                        score -= 3;
                    } else if (piece === 'WK') {
                        score -= 5;
                    }
                }
                return score;
            }


            function computerMove() {
                var boardCopy = startingBoard.slice();
                var depth = 3;
                var randomChance = Math.random();

                if (randomChance < 0.2) {
                    depth = 1;
                } else if (randomChance <0.4) {
                    depth = 2;
                }

                // Alternative random move selection
                /*
                if (randomChance < 0.2) {
                    var possibleMoves = [];
                    for (var i = 0; i < 64; i++) {
                        if (startingBoard[i] === 'B' || startingBoard[i] === 'BK') {
                            var moves = getPossibleMoves(i);
                            for (var j = 0; j < moves.length; j++) {
                                possibleMoves.push({ from: i, to: moves[j].to, capture: moves[j].capture });
                            }
                        }
                    }

                    if (possibleMoves.length > 0) {
                        
                        var capturingMoves = [];
                        for (var k = 0; k < possibleMoves.length; k++) {
                            if (possibleMoves[k].capture !== null) {
                                capturingMoves.push(possibleMoves[k]);
                            }
                        }

                        var move;
                        if (capturingMoves.length > 0) {
                            move = capturingMoves[Math.floor(Math.random() * capturingMoves.length)];
                        } else {
                            move = possibleMoves[Math.floor(Math.random() * possibleMoves.length)];
                        }

                        movePiece(move.from, move.to);
                        if (!checkEndGame()) {
                            switchTurn();
                        }
                    }
                    return;
                }
                */

                var { move } = minimax(boardCopy, depth, true, -Infinity, Infinity);
                if (move) {
                    movePiece(move.from, move.to);

                    if (move.capture !== null && hasCapturingMovesAt(move.to)) {
                        selectedPiece = move.to;
                        setTimeout(computerMove, 500);
                    } else {
                        selectedPiece = null;
                        if (!checkEndGame()) {
                            switchTurn();
                        }
                    }
                } else {
                    switchTurn();
                }
            }


            function renderBoard() {
                for (var i = 0; i < 64; i++) {
                    var cell = document.getElementById('cell' + i);
                    if (cell) {
                        cell.innerHTML = '';

                        if (startingBoard[i] === 'B') {
                            cell.innerHTML = `<span class="piece"><img src='${blackPiece}' alt="Black Piece" /></span>`;
                        } else if (startingBoard[i] === 'W') {
                            cell.innerHTML = `<span class="piece"><img src='${whitePiece}' alt="White Piece" /></span>`;
                        } else if (startingBoard[i] === 'BK') {
                            cell.innerHTML = `<span class="piece"><img src='${blackKing}' alt="Black King" /></span>`;
                        } else if (startingBoard[i] === 'WK') {
                            cell.innerHTML = `<span class="piece"><img src='${whiteKing}' alt="White King" /></span>`;
                        }
                    }
                }
            }

            function startNewGame() {
                unhighlightSquare(currentSelection);
                currentPlayer = 'W';
                currentSelection = 56;
                startingBoard = ['', '', '', '', '', '', '', '',
                    'B', 'B', 'B', 'B', 'B', 'B', 'B', 'B',
                    'B', 'B', 'B', 'B', 'B', 'B', 'B', 'B',
                    '', '', '', '', '', '', '', '',
                    '', '', '', '', '', '', '', '',
                    'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W',
                    'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W',
                    '', '', '', '', '', '', '', '',
                ];
                renderBoard();
                highlightSquare(currentSelection);
                clearHighlights();
                blackScore = 0;
                whiteScore = 0;
                document.getElementById('playerXWins').innerText = whiteScore;
                document.getElementById('playerOWins').innerText = blackScore;
                document.getElementById('notification').innerText = 'Beyaz taşları oynatınız.';
            }

            renderBoard();
            highlightSquare(currentSelection);
            return {
                moveSelection, startNewGame, showRules, handleEnterKey
            };


        };

        const gameFunctions = game();
        let navigationHandler = createNavigationHandler(gameFunctions);
        document.addEventListener('keydown', navigationHandler);

        function createNavigationHandler(
            { moveSelection, startNewGame, showRules, handleEnterKey }
        ) {
            return function (e) {
                switch (e.keyCode) {
                    case TVKEYS.VK_LEFT:
                        moveSelection(-1);
                        break;
                    case TVKEYS.VK_RIGHT:
                        moveSelection(1);
                        break;
                    case TVKEYS.VK_UP:
                        moveSelection(-8);
                        break;
                    case TVKEYS.VK_DOWN:
                        moveSelection(8);
                        break;
                    case TVKEYS.VK_GREEN:
                        startNewGame();
                        break;
                    case TVKEYS.VK_YELLOW:
                        showRules();
                        break;
                    case TVKEYS.VK_RED:
                        deactivateApp();
                        break;
                    case TVKEYS.VK_ENTER:
                        handleEnterKey();
                        break;
                    case TVKEYS.VK_BLUE:
                        document.removeEventListener('keydown', navigationHandler);
                        document.getElementById('backButton').click();
                        break;
                    default:
                        break;
                }
            };
        }


    }

}

export default Dama;
